import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import * as CANNON from "cannon-es";
import TWEEN from "@tweenjs/tween.js";
import { updateDotPosition, processGLTF } from "./utils.js";

let woodlogInstance = null;

class Woodlog {
	constructor(scene, world, physicsMaterial, meshes, bodies, camera, listener) {
		this.scene = scene;
		this.world = world;
		this.physicsMaterial = physicsMaterial;
		this.meshes = meshes;
		this.bodies = bodies;
		this.camera = camera;
		this.listener = listener;

		this.model = null;
		this.sound = null;
		this.isCameraClose = false;

		this.raycaster = new THREE.Raycaster();
		this.mouse = new THREE.Vector2();

		this.tempVector = new THREE.Vector3();

		this.loadModel();
	}

	loadModel() {
		const dracoLoader = new DRACOLoader();
		dracoLoader.setDecoderPath("/assets/gltf/");

		const loader = new GLTFLoader();
		loader.setDRACOLoader(dracoLoader);
		loader.load("/assets/gltf/woodlog.glb", (gltf) => {
			processGLTF(gltf.scene);
			this.model = gltf.scene;
			this.setupWoodlog();
		});
	}

	setupWoodlog() {
		this.model.scale.set(4, 4, 4);
		this.model.position.set(-7, -1, 1.5);
		this.scene.add(this.model);

		const woodlogBody = new CANNON.Body({
			mass: 0,
			position: new CANNON.Vec3(...this.model.position.toArray()),
			shape: this.createDetailedWoodlogShape(),
			material: this.physicsMaterial,
		});
		this.world.addBody(woodlogBody);
		this.bodies.push(woodlogBody);
		this.meshes.push(this.model);

		this.updateDotPosition();
		this.setupSound();
		this.addClickListener();
	}

	updateDotPosition() {
		updateDotPosition(
			this.model,
			document.querySelector(".dot-kitchen"),
			60,
			0,
			this.camera
		);
	}

	setupSound() {
		this.sound = new THREE.PositionalAudio(this.listener);
		const audioLoader = new THREE.AudioLoader();
		audioLoader.load("/assets/sounds/woodlog.m4a", (buffer) => {
			this.sound.setBuffer(buffer);
			this.sound.setRefDistance(10);
			this.sound.setLoop(true);
			this.sound.setVolume(0.1);
		});
		this.model.add(this.sound);
	}

	addClickListener() {
		window.addEventListener("click", this.onMouseClick.bind(this));
	}

	onMouseClick(event) {
		this.mouse.set(
			(event.clientX / window.innerWidth) * 2 - 1,
			-(event.clientY / window.innerHeight) * 2 + 1
		);

		this.raycaster.setFromCamera(this.mouse, this.camera);
		const intersects = this.raycaster.intersectObject(this.model, true);

		if (intersects.length > 0) {
			this.toggleSound();
			this.toggleCameraPosition();
		}
	}

	toggleSound() {
		if (this.sound.isPlaying) {
			this.sound.pause();
		} else {
			this.sound.play();
		}
	}

	toggleCameraPosition() {
		const targetPosition = this.isCameraClose
			? this.tempVector.set(0.1, 5, 30)
			: this.tempVector
					.copy(this.model.position)
					.add(new THREE.Vector3(-8, -5, 5));

		const lookAtPosition = this.isCameraClose
			? new THREE.Vector3(0, 0, 0)
			: this.model.position;

		this.moveCameraToPosition(targetPosition, lookAtPosition);
		this.isCameraClose = !this.isCameraClose;
	}

	moveCameraToPosition(position, lookAtPosition) {
		new TWEEN.Tween(this.camera.position)
			.to(position, 2000)
			.easing(TWEEN.Easing.Quadratic.InOut)
			.onUpdate(() => this.camera.lookAt(lookAtPosition))
			.start();
	}

	createDetailedWoodlogShape() {
		const size = new THREE.Box3()
			.setFromObject(this.model)
			.getSize(new THREE.Vector3())
			.multiplyScalar(0.5);
		return new CANNON.Box(new CANNON.Vec3(size.x, size.y, size.z));
	}

	getModel() {
		return this.model;
	}
}

export function loadWoodlog(
	scene,
	world,
	physicsMaterial,
	meshes,
	bodies,
	camera,
	listener
) {
	woodlogInstance = new Woodlog(
		scene,
		world,
		physicsMaterial,
		meshes,
		bodies,
		camera,
		listener
	);
	return woodlogInstance;
}

export function getWoodlog() {
	return woodlogInstance ? woodlogInstance.getModel() : null;
}
